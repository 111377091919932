import React from "react";
import { Text } from "../../components";

const AGATStakingRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        AGAT Staking is a way to support the development of Agates Network by staking cryptocurrency in the early stages of the project, and to obtain long-term, sustainable and considerable benefits from the rapid growth of Agates Network.
        <br/>
        For users who participate in AGAT Staking, they can not only obtain a larger total amount of AGAT coins and higher amounts of benefits than ordinary miners, but also participate in project governance and decision-making as members of the core team.
        <br/>
        For the Agates Network project, with the help of these users, more funds can be used to promote technology research and growth of the project. With the increase in the number of users, the expansion of the business ecosystem, and the increase in total revenue, it can in turn provide more benefits to users, especially those who participate in AGAT Staking.
        <br />
        It should be noted that the Staking Allowance allocated to AGAT Stakers and the Mining Reward and Holding Dividend allocated to all users all use independent distribution mechanisms and budgets, so there will be no impact on each other. Moreover, the same user can receive multiple rewards at the same time.
        <br />
        You can learn more details about this on the AGAT Staking page.
      </Text>
    </div>


);
};

export default AGATStakingRules;
