import React from "react";
import { Text } from "../../components";

const WithdrawFeature = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        At the current stage, Agates Network allows users to withdraw USDT to any personal wallet that supports the ERC-20 network. Later, when the Agates Mainnet is officially released, users will be allowed to withdraw AGAT coins to any personal wallet that supports Agates Mainnet.
        <br/>
        The profits earned by users from Agates Network through various reward events (please refer to "How to earn profits from Agates Network?") will be credited in the Withdrawable USDT in Agates Wallet. Users can choose to withdraw some or all of these USDT to a personal wallet.
        <br/>
        Users need to comply with the regulations of Agates Wallet when they submit a withdrawal application and pay a certain withdrawal fee. When the application is approved, the USDT amount will be sent to the wallet address specified by the user.
        <br />
        You can learn more details about this topic on the Withdraw page.
      </Text>
    </div>


);
};

export default WithdrawFeature;
