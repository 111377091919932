import React from "react";
import { useParams } from "react-router-dom";
import ProjectIntroduction from "./FAQ/1. What is Agates Network";
import ProjectMission from "./FAQ/2. What is Agates Network_s mission";
import ProjectFeatures from "./FAQ/3. Why is Agates Network unique";
import ParticipateRules from "./FAQ/4. How to participate in Agates Network";
import InviteParticipateRules from "./FAQ/5. How to invite others to participate";
import EcosystemIntroduction from "./FAQ/6. What is Agates Ecosystem";
import EcosystemValue from "./FAQ/7. What is Agates Ecosystem_s value";
import AGATIntroduction from "./FAQ/8. What is AGAT";
import AGATBenefitRules from "./FAQ/9. What are the benefits from AGAT";
import MineAGATRules from "./FAQ/10. How to mine AGAT coins";
import AGATPrice from "./FAQ/11. How about the price of AGAT";
import AGATCirculation from "./FAQ/12. How is AGAT coin circulated";
import ProfitRules from "./FAQ/13. How to earn profits";
import WithdrawFeature from "./FAQ/14. How to withdraw";
import AGATStakingRules from "./FAQ/15. What is AGAT Staking";
import ProjectTeam from "./FAQ/16. How is the Agates Network team";
import ProjectFutureVison from "./FAQ/17. What is our vision for the future";
import HoldingDividendRules from "./RewardActivity/How to earn Holding Dividend";
import MingingRewardRules from "./RewardActivity/How to earn Mining Reward";
import PromotingBonusRules from "./RewardActivity/How to earn Promoting Bonus";
import StakingAllowanceRules from "./RewardActivity/How to earn Staking Allowance";
import NotFound from "./NotFound";
const SecondPage = () => {
  const { first, second} = useParams();
  let page: {};
  switch (first) {
    case "faq":
      switch (second) {
        case "projectIntroduction":
          page = <ProjectIntroduction />;
          break;
        case "projectMission":
          page = <ProjectMission />;
          break;
        case "projectFeatures":
          page = <ProjectFeatures />;
          break;
        case "participateRules":
          page = <ParticipateRules />;
          break;
        case "inviteParticipateRules":
          page = <InviteParticipateRules />;
          break;
        case "ecosystemIntroduction":
          page = <EcosystemIntroduction />;
          break;
        case "ecosystemValue":
          page = <EcosystemValue />;
          break;
        case "AGATIntroduction":
          page = <AGATIntroduction />;
          break;
        case "AGATBenefitRules":
          page = <AGATBenefitRules />;
          break;
        case "mineAGATRules":
          page = <MineAGATRules />;
          break;
        case "AGATPrice":
          page = <AGATPrice />;
          break;
        case "AGATCirculation":
          page = <AGATCirculation />;
          break;
        case "profitRules":
          page = <ProfitRules />;
          break;
        case "withdrawFeature":
          page = <WithdrawFeature />;
          break;
        case "AGATStakingRules":
          page = <AGATStakingRules />;
          break;
        case "projectTeam":
          page = <ProjectTeam />;
          break;
        case "projectFutureVison":
          page = <ProjectFutureVison />;
          break;
        default:
          page = <NotFound />;
          break;
      }
      break;
    case "rewardActivity":
      switch (second) {
        case "holdingDividendRules":
          page = <HoldingDividendRules />;
          break;
        case "mingingRewardRules":
          page = <MingingRewardRules />;
          break;
        case "promotingBonusRules":
          page = <PromotingBonusRules />;
          break;
        case "stakingAllowanceRules":
          page = <StakingAllowanceRules />;
          break;
        default:
          page = <NotFound />;
          break;
      }
      break;
    default:
      page = <NotFound />;
  }

  return (
    <div>
      {page}
    </div>
  );
};
export default SecondPage;
