import React from "react";
import { Text } from "../../components";

const AGATCirculation = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Agates Network provides users with easy-to-use, high-speed, and low-cost AGAT circulation tools on its Agates Network App.
        <br/>
        You can use the "Send" function in Agates Wallet to send AGAT coins to other users, or use the "Receive" function to obtain AGAT coins from other users. In this process, you need to use Agates Wallet Address to uniquely mark a user.
        <br/>
        In addition, these 2 functions can also be used to send or receive USDT in Agates Wallet.
        <br />
        You can learn more details about this on the Wallet page.
      </Text>
    </div>


);
};

export default AGATCirculation;
