import React from "react";
import { Text } from "../components";

const UserAgreement = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[18px] text-gray-900 w-full"
            size="txtInterSemiBold48">User Agreement
      </Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full" size="txtInterRegular14">
        Welcome to Agates Network!
        <br />
        <br />
        This User Agreement ("Agreement") governs your use of the Agates Network mobile application ("App"). By
        downloading, installing, or using the App, you agree to be bound by the terms and conditions of this Agreement.
        Please read and understand all terms and conditions of this User Agreement carefully before you start using this
        App.
        <br />
        When you fill in the information on the registration page and complete the registration process, it means that
        you have fully understood and accepted the terms and conditions of this User Agreement. If you do not agree with
        any of its terms or conditions(especially the disclaimer or limitation of liability, applicable law, dispute
        resolution, etc.), you should immediately stop the registration process.
        <br />
        <ol className={"custom-ol"}>
          <li>Introduction and Acceptance of Terms
            <ol className={"custom-ol"}>
              <li>Project introduction
                <br />
                Welcome to Agates Network (hereinafter referred to as the "Project"). Agates Network is a decentralized
                ecosystem designed to provide users with diversified digital services and social interactions. Through
                this platform, users can enjoy rich services and functions such as e-commerce, digital content, social
                entertainment, and online games.
              </li>
              <li>Acceptance of Terms
                <br />
                Please read and understand all the terms and conditions in this User Agreement carefully before using
                the services provided by this platform. Your registration, login or use of any services on this platform
                will be deemed that you have read, understood and agreed to abide by the terms of this Agreement. If you
                do not agree with any content of this agreement, please stop using this platform service. When you use
                the services of this platform, you will be deemed to have fully understood and accepted all the contents
                of this Agreement.
              </li>
            </ol>

          </li>
          <br />
          <li>Account Registration and Management
            <ol className={"custom-ol"}>
              <li>Account Registration
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    You need to register an account to use the services provided by this project. When registering, you
                    must provide accurate and truthful information and ensure that it is promptly updated.
                  </li>
                  <li>
                    When registering your account, you need to set a secure PIN code and a mnemonic phrase (hereinafter
                    referred to as "verification information") and keep your account information and verification
                    information confidential. You will be responsible for all activities conducted using your account
                    and verification information.
                  </li>
                  <li>
                    If the information you provide is not true, accurate, or complete, or if the project has reasonable
                    grounds to suspect that the information you provide is not true, accurate, or complete, the project
                    has the right to suspend or terminate your account and refuse your use of the project's services.
                  </li>
                </ol>
              </li>
              <li>Account Management
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    You are responsible for properly safeguarding your account information and verification information
                    and ensuring the proper custody of your account and verification information.
                  </li>
                  <li>
                    You should update your account information in a timely manner to ensure that it is true, accurate,
                    complete, and timely. You shall bear any loss or liability caused by the untrue, inaccurate,
                    incomplete, or untimely update of the information provided by you.
                  </li>
                  <li>
                    If you discover any unauthorized use of your account, you should immediately notify the project and
                    follow the instructions of the project for further actions.
                  </li>
                </ol>
              </li>
              <li>Account Security
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    You should properly safeguard your account and verification information and shall not transfer,
                    lend, rent, or disclose it to any third party.
                  </li>
                  <li>
                    If you lose your account verification information or discover that your account is being illegally
                    used by others, you should immediately notify the project and follow the instructions of the project
                    for further actions.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>User Code of Conduct
            <ol className={"custom-ol"}>
              <li>Legal Use
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    You shall comply with the laws and regulations of your country/region and shall not engage in any
                    illegal or prohibited activities using this project.
                  </li>
                  <li>
                    You shall respect the legitimate rights and interests of others and shall not infringe upon the
                    intellectual property rights, privacy rights, reputation rights, and other legitimate rights and
                    interests of others.
                  </li>
                </ol>
              </li>

              <li>Information Publication
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Any information you publish shall be true, accurate, and legal. You shall not publish false,
                    misleading, pornographic, violent, or otherwise illegal or prohibited information.
                  </li>
                  <li>
                    You shall bear legal responsibility for any information you publish. The project does not review the
                    information you publish but reserves the right to supervise and manage it within a reasonable range.
                  </li>
                </ol>
              </li>
              <li>Prohibited Behavior
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    It is prohibited to engage in any illegal, prohibited, or infringing activities using this project,
                    including but not limited to spreading viruses, Trojans, and other malicious code, and attacking
                    network security.
                  </li>
                  <li>
                    It is prohibited to engage in any illegal or prohibited activities such as spamming, advertising,
                    and commercial activities using this project.
                  </li>
                  <li>
                    It is prohibited to interfere with, disrupt, or damage the normal operation of this project,
                    including but not limited to attacking, invading, tampering with, and destroying the network systems
                    and servers of this project.
                  </li>
                </ol>
              </li>
              <li>Violation Handling
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    The project has the right to take corresponding measures against behaviors that violate the user
                    code of conduct, including but not limited to warnings, temporary account suspension, and permanent
                    account suspension.
                  </li>
                  <li>
                    You shall bear legal responsibility for any losses caused to the project or any third party due to
                    your violation of the user code of conduct, and compensate for the losses incurred as a result.
                  </li>
                </ol>
              </li>

            </ol>

          </li>
          <br />
          <li>Privacy Policy
            <ol className={"custom-ol"}>
              <li>Information Collection and Use
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    We may collect your personal information, including but not limited to your name, contact
                    information, email address, etc., to provide services and support to you.
                  </li>
                  <li>
                    We will strictly protect your personal information and will not disclose or sell your personal
                    information to third parties without your permission.
                  </li>
                  <li>
                    We may use your personal information for marketing purposes or to send you marketing information,
                    but you have the right to opt out of receiving such information.
                  </li>
                </ol>
              </li>
              <li>Information Security
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    We will take necessary technical and managerial measures to protect the security of your personal
                    information, prevent information loss, leakage, tampering, or unauthorized access.
                  </li>
                  <li>
                    We will use secure encryption technology during information transmission to ensure the security and
                    reliability of your personal information transmission.
                  </li>
                </ol>
              </li>
              <li>Cookies and Similar Technologies
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    We may use cookies and similar technologies to collect and store your information to improve the
                    efficiency and user experience of the website.
                  </li>
                  <li>
                    You can choose to accept or reject cookies, but rejecting cookies may affect your normal use of the
                    website.
                  </li>
                </ol>
              </li>
              <li>Other Terms
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    We reserve the right to modify this privacy policy at any time, and the modified policy will be
                    posted on the website.
                  </li>
                  <li>
                    If you have any questions or opinions about our privacy policy, please feel free to contact us.
                  </li>
                </ol>
              </li>
              <li>Scope of Application
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    This privacy policy applies to all users who use the services of this project, and your use of the
                    services of this project is deemed as your acceptance of this privacy policy.
                  </li>
                </ol>
              </li>

            </ol>
          </li>
          <br />
          <li>Intellectual Property
            <ol className={"custom-ol"}>
              <li>Ownership
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    The ownership of the Agates Network project and its related services belongs to Agates Network.
                  </li>
                  <li>
                    Agates Network reserves the ownership of all intellectual property rights of the project, including
                    but not limited to trademarks, logos, website content, application content, software, and
                    technology.
                  </li>
                </ol>
              </li>
              <li>License to Use
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Without explicit permission from Agates Network, no one may use the intellectual property of the
                    Agates Network project in any form.
                  </li>
                  <li>
                    Users are granted a limited, non-exclusive license to use the services provided by the Agates
                    Network project.
                  </li>
                </ol>
              </li>
              <li>Infringement Handling
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    If any unauthorized use of Agates Network intellectual property is discovered, Agates Network
                    reserves the right to pursue legal liability.
                  </li>
                  <li>
                    Users should respect and protect the intellectual property of the Agates Network project. In case of
                    infringement, users shall bear corresponding legal responsibilities.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Service Provision and Changes
            <ol className={"custom-ol"}>
              <li>Provision of Services
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network is committed to providing stable and reliable services to meet users' needs and
                    expectations. We continuously optimize and improve our services to ensure the best user experience.
                  </li>
                  <li>
                    We offer diverse services tailored to users' actual needs, including but not limited to trading,
                    information dissemination, community interaction, etc., catering to various user groups.
                  </li>
                </ol>
              </li>
              <li>Changes and Interruptions of Services
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network reserves the right to adjust and change the content of services according to
                    operational needs and market demands, aiming to provide a better service experience.
                  </li>
                  <li>
                    In case of significant changes or adjustments to the service content, we will notify users in
                    advance and strive to minimize the impact on them. Users can make corresponding adjustments and
                    adaptations based on their needs and circumstances.
                  </li>
                  <li>
                    Despite our best efforts to ensure the continuity and stability of services, interruptions or
                    disruptions may occur due to force majeure, technical failures, or other unforeseen circumstances.
                    In such cases, we will promptly restore services and provide necessary support and assistance to
                    users.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Fees and Payment
            <ol className={"custom-ol"}>
              <li>Service Fees
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network's basic services are provided free of charge, and users do not need to pay any fees
                    to use them.
                  </li>
                  <li>
                    For certain special or value-added services, we may charge a fee. In such cases, we will inform
                    users of the relevant fee standards in advance and obtain their consent before charging fees.
                  </li>
                </ol>
              </li>
              <li>Payment Methods
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Users can pay fees through various methods, including but not limited to digital currencies, bank
                    transfers, third-party payment platforms, etc.
                  </li>
                  <li>
                    During the payment process, users need to comply with the corresponding payment regulations and
                    procedures to ensure the accuracy and security of the payment.
                  </li>
                </ol>
              </li>
              <li>Fee Adjustments
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network reserves the right to adjust service fees based on market demand and operational
                    conditions. When adjusting fees, we will notify users in advance and minimize the impact on them.
                  </li>
                  <li>
                    Fee adjustments may vary based on factors such as service content, frequency, duration, etc. Users
                    should pay close attention to relevant notifications and adjust their payment plans and budgets
                    promptly.
                  </li>
                </ol>
              </li>
              <li>Refund Policy
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    If users need a refund due to their own reasons for the fees already paid, they can submit a refund
                    request to us.
                  </li>
                  <li>
                    In cases where the refund conditions are met, we will process the user's refund request as soon as
                    possible and refund the fees to the user according to the agreed method.
                  </li>
                  <li>
                    The final interpretation of the refund conditions is owned by Agates Network.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Disclaimer and Limitation of Liability
            <ol className={"custom-ol"}>
              <li>Disclaimer
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Users should bear the risks when using Agates Network services, and we are not responsible for any
                    direct or indirect losses incurred by users from using the services.
                  </li>
                  <li>
                    We shall not be liable for service interruptions, data loss, etc., caused by reasons beyond our
                    control, including but not limited to force majeure, computer viruses, hacker attacks, system
                    instability, etc.
                  </li>
                </ol>
              </li>
              <li>Limitation of Liability
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    To the extent permitted by law, we shall not be liable for any losses or damages incurred by users
                    from using Agates Network services, regardless of foreseeability.
                  </li>
                  <li>
                    Under no circumstances shall our liability for direct losses exceed the total amount of service fees
                    paid by the user to us in the past 3 months, but the user himself/herself is required to provide
                    direct evidence to prove that the direct loss is due to the responsibility of the Agates Network
                    project.
                  </li>
                </ol>
              </li>
              <li>Mandatory Terms
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    If applicable laws prohibit the exclusion or limitation of certain types of liability, some
                    provisions of this disclaimer and limitation of liability may not apply to you, but other provisions
                    shall remain valid.
                  </li>
                </ol>
              </li>
              <li>Agreement Amendments
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    We reserve the right to modify this disclaimer and limitation of liability at any time. The revised
                    terms will be published on the Agates Network website and will be effective upon publication.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Termination of Agreement and Dispute Resolution
            <ol className={"custom-ol"}>
              <li>Termination of Agreement
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Users have the right to terminate this agreement at any time by deleting their account or ceasing to
                    use Agates Network services.
                  </li>
                  <li>
                    We reserve the right to suspend or terminate the provision of Agates Network services to users at
                    any time based on the user's violation of this agreement, including but not limited to suspending or
                    terminating the user's account.
                  </li>
                </ol>
              </li>
              <li>Dispute Resolution
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    The interpretation, execution, and dispute resolution of this agreement shall be governed by the
                    rules formulated by the Agates Network Project Oversight Committee.
                  </li>
                  <li>
                    Both parties shall resolve any disputes arising from this agreement through amicable negotiation. If
                    negotiation fails, either party may appeal to the Project Oversight Committee.
                  </li>
                </ol>
              </li>
              <li>Others
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Upon termination or expiration of this agreement, users shall cease to use Agates Network services,
                    and we shall have the right to delete users' relevant information and data.
                  </li>
                  <li>
                    Termination or expiration of the agreement does not affect users' obligations and responsibilities
                    under this agreement.
                  </li>
                </ol>
              </li>
              <li>Mandatory Terms
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    If applicable laws prohibit the exclusion or limitation of certain types of liability, some
                    provisions of this section on termination of agreement and dispute resolution may not apply to you,
                    but other provisions shall remain valid.
                  </li>
                  <li>
                    Agates Network is a global project operated by the non-entity Agates Network DAO team. Any legal
                    matters related to the Agates Network project will be handled by a team of legal professionals
                    located in mainland China. So, the formation, effectiveness, interpretation, modification,
                    supplement, termination, execution and dispute resolution of this Agreement shall be governed by the
                    laws of the mainland of the People's Republic of China. In the absence of relevant provisions of the
                    law, commercial practice or industry practice shall be referred to.
                  </li>
                  <li>
                    Disputes arising from your use of Agates Network services will be resolved by the Agates Network DAO
                    team through negotiation with you. If negotiation fails, either party may submit to the Beijing
                    Arbitration Commission for arbitration, which is final and binding on both parties.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Prohibition of Assignment of Agreement Rights
            <ol className={"custom-ol"}>
              <li>Transfer Restrictions
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Users may not transfer the rights and obligations under this agreement to any third party, including
                    but not limited to assignment, rental, transfer, or disposal by other means.
                  </li>
                </ol>
              </li>
              <li>Rights of Agates Network
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network reserves the right to refuse any agreement transfer without its prior written
                    consent, and any attempted transfer shall be deemed invalid.
                  </li>
                </ol>
              </li>
              <li>User Responsibilities
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Users agree not to attempt to transfer their rights and obligations under this agreement by any
                    means, otherwise bearing corresponding legal responsibilities.
                  </li>
                </ol>
              </li>
              <li>Agates Network Reserves the Right
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network reserves the right to modify or update this agreement as needed and will notify users
                    accordingly when appropriate.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Agreement Modification and Effectiveness
            <ol className={"custom-ol"}>
              <li>Modification Rights
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network reserves the right to modify the terms of this agreement at any time, and the
                    modified agreement will be announced on the website and take effect from the date of announcement.
                  </li>
                  <li>
                    Users should regularly review this agreement to understand any changes. Continued use of Agates
                    Network services by users will be deemed as acceptance of the modified agreement.
                  </li>
                </ol>
              </li>
              <li>Notification Method
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    Agates Network will notify users of the agreement modifications through application announcement,
                    website announcements, emails, or other appropriate means.
                  </li>
                  <li>
                    Users agree that Agates Network may send notifications to them through the above-mentioned methods.
                  </li>
                </ol>
              </li>
              <li>Effective Date
                <br />
                <ol className={"custom-ol"}>
                  <li>
                    By continuing to use Agates Network services, users agree to comply with the modified agreement,
                    which will take effect from the user's acceptance.
                  </li>
                </ol>
              </li>
            </ol>

          </li>
          <br />
          <li>Contact Us
            <br />
            If you have any questions or feedback regarding this user agreement or the project, please feel free to
            contact us.
            <br />
            Contact Email: agates.network@gmail.com
          </li>
          <br />
          <li>Effective Date
            <br />
            This User Agreement becomes effective from the date you start using the project.
            <br />
            Thank you for reading and understanding.
            <br />
            We wish you a pleasant experience!
          </li>
          <br />
          <li>Appendix:
            <ol className={"custom-ol"}>
              <li>Force Majeure: Natural disasters such as earthquakes, fires, floods, hurricanes, etc. including:
                <br />
                <ul className="custom-ul">
                  <li>
                    War, unrest, riots, or terrorist attacks.
                  </li>
                  <li>
                    Government actions, such as policy changes, enactment, or amendment of laws and regulations.
                  </li>
                  <li>
                    Hacking attacks, network security vulnerabilities, or network failures.
                  </li>
                  <li>
                    Supply chain interruptions or logistics problems.
                  </li>
                  <li>
                    Labor disputes, strikes, or social instability.
                  </li>
                  <li>
                    Power outages or energy supply issues.
                  </li>
                  <li>
                    Epidemics, outbreaks of infectious diseases, or public health events.
                  </li>
                  <li>
                    Equipment failures or technical malfunctions.
                  </li>
                  <li>
                    Other unforeseeable events or reasons.
                  </li>
                </ul>
              </li>
            </ol>

          </li>
        </ol>
      </Text>

    </div>

  );
};

export default UserAgreement;




