import React from "react";
import { Text } from "../components";

const InvitationRules = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          AGAT Staking is a way to support the development of Agates Network by staking cryptocurrency in the early stages of the project, and to obtain long-term, sustainable and considerable benefits from the rapid growth of Agates Network.
          </li>
          <li>
          Each AGAT Staking participant (AGAT Staker) needs to pledge a certain amount of cryptocurrency (currently USDT is supported, and more cryptocurrencies will be supported in the future) to Agates Network to be eligible to receive exclusive benefits and privileges from Agates Network.
          </li>
          <li>
          Each AGAT Staking will bring the following benefits to its AGAT Staker:
            <ol>
              <li>
              A certain amount of AGAT coins every week. The specific AGAT amount is directly proportional to the staking amount. You can find the detailed data about the staking amount and the number of AGAT coins you will receive on the AGAT Staking page.
              </li>
              <li>
              Higher amounts of Mining Reward and AGAT Holding Dividend, because these two reward amounts are proportional to the number of AGAT coins you newly mine or hold respectively.
              </li>
              <li>
              Exclusive and considerable Staking Allowance. You can find the detailed description of Staking Allowance in the AGAT Staking page.
              </li>
              <li>
              Many other exclusive benefits and privileges. You can find more detailed descriptions in the AGAT Staking page.
              </li>
            </ol>
          </li>
          <li>
          Each user can participate in AGAT Staking multiple times, and the above benefits for each AGAT Staking will be calculated separately based on the respective take-effect time.
          </li>
          <li>
          The staking period of AGAT Stake starts when each pledge takes effect and ends when the Agates mainnet is released. When the Agates mainnet is released, AGAT Stakers can choose to release all the pledged cryptocurrencies in order, or exchange all pledged cryptocurrencies for equivalent AGAT coins at 10% of the AGAT market price.
          </li>
          <li>
          When each AGAT Staking takes effect for 6 months, its staker can choose to release the staking early. If the staker chooses to release the staking early, Agates Network will deduct 50% of the staking amount as the early release fee. After that, the AGAT Staker will no longer receive the Staking Mining AGAT coins and Staking Allowance corresponding to this AGAT Staking.
          </li>
          <li>
          In addition, every core user who recognizes the values and ideals of the Agates Network project can earn Referral Staking Reward of up to 20% of the total staking amount in batches by promoting AGAT Staking.
          </li>
          <li>
          Staking is a common benefit mechanism in blockchain projects. While obtaining staking benefits, all stakers also need to fully understand the detailed rules of staking to be aware of the possible risks. The unique feature of AGAT Staking is that during the staking period, AGAT Stakers will regularly receive benefits in the form of USDT or others as hedge for any potential risks.
          </li>
          <li>
          Agates Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>


);
};

export default InvitationRules;





