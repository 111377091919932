import React from "react";
import { Text } from "../components";

const ReceiveRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          With the Receive function, you can receive AGAT or USDT from other users.
          </li>
          <li>
          Save the QR code above, or directly copy the Wallet Address.
          </li>
          <li>
          Send the QR code or Wallet Address to other users, and they can send AGAT or USDT to this address through the Send function in Agates Network App.
          </li>
          <li>
          The AGAT or USDT sent to you will be credited into your Agates Wallet within 0.01-0.05 seconds.
          </li>
          <li>
          The above QR code and Wallet Address belongs only to your Agates ID and is permanently valid.
          </li>
          <li>
          Agates Network DAO will regularly propose adjustments to the rules based on project needs and implement them after approval by the Management Committee.
          </li>
        </ol>
      </Text>
    </div>
    
);
};

export default ReceiveRules;