import React from "react";
import { Text } from "../../components";

const ProjectFeatures = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Agates Network project adopts a series of innovations to ensure that it promotes its development and achieves its ultimate goal through the perfect combination of technology and business.
        <br/>
        These innovations include:
        <br/>

        <ol>
          <li>
          Free access to all global users.
          </li>
          <li>
          Multiple dividend events to enable all users to contribute to the project and receive corresponding benefits.
          </li>
          <li>
          A prosperous business ecosystem as the foundation of the overall commercial value of the project.
          </li>
          <li>
          Multiple verification mechanisms to ensure absolute account and privacy security.
          </li>
          <li>
          Free circulation of the coins.
          </li>
        </ol>

        <br />
        You can learn more details about this topic in the white paper.

      </Text>
    </div>


);
};

export default ProjectFeatures;
