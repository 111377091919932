import React from "react";
import { Text } from "../components";

const InvitationRules = () => {
  return (
 
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[18px] text-gray-900 w-full"
            size="txtInterSemiBold48">How to invite?</Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Send an invitation to your friends. Make sure the invitation includes a link to the Agates Network App and your own Agates ID (which is also your invitation code). 
          </li>
          <li>
          Also, you can also post the invitation on your social media account or in any group.
          </li>
          <li>
          When someone installs the Agates Network App, and fills in your Agates ID in the "Inviter's Agates ID" blank when signing up, your invitation is complete!
          </li>
        </ol>
      </Text>
      <br />
      <Text className="text-[18px] text-gray-900 w-full mt-3"
            size="txtInterSemiBold48">What to benefit from inviting?</Text>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">
        <ol>
          <li>
          Every time you successfully invite a new user, you will immediately receive 5,000 Crediting AGAT coins.
          </li>
          <li>
          When any of your invitees gets Credited AGAT through Daily Mining, Purchasing or AGAT Staking, an equal amount of your Crediting AGAT will be automatically converted into Credited AGAT in your Agates Wallet, until all of the 5,000 Crediting AGAT are converted.
          </li>
          <li>
          Every user you invite, as well as the users invited by your invitees and so on, will become a member of your Agates Team. When members of your Agates Team make consumption or staking in the Agates Store or in AGAT Staking, you will receive additional AGAT coins as rebates.
          </li>
          <li>
          The above rule 3 is permanently valid during the existence of the Agates Network project. 
          </li>
        </ol>
      </Text>
    </div>

);
};

export default InvitationRules;