import React from "react";
import { Text } from "../../components";

const ProjectTeam = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Agates Network team firmly believes in the power of "consensus" and the value of "decentralization". Therefore, Agates Network team is composed of a group of experienced professionals scattered in multiple cities around the world. It's called Agates Network DAO (Decentralized Autonomous Organization).
        <br/>
        The professionals within Agates Network DAO have different professional knowledge in blockchain technology, business development, marketing and finance, and have deep experience in the Internet industry, working with the top companies like Apple, Facebook, Google, Tencent, Alibaba, JD, etc. They are committed to driving the project vision forward through innovation, collaboration and transparency, ensuring project success and delivering value to users and stakeholders alike.
        <br/>
        Agates Network DAO is not a closed organization, but will be dynamically adjusted according to the needs of the project. Everyone who recognizes the concept and vision of Agates Network and has the professional knowledge required by the project can apply to the Management Committee to join the Agates Network DAO.
        <br />
        You can learn more details in the white paper.
      </Text>
    </div>
);
};

export default ProjectTeam;


