import React from "react";
import { Text } from "../../components";

const AGATPrice = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        The price of AGAT is affected by a variety of factors, including market demand, supply dynamics, trading volume, external market conditions, etc. When AGAT opens to public trading, these factors will comprehensively affect users' recognition of AGAT's value, which will be reflected in real-time fluctuations in transaction prices.
        <br/>
        At this stage, the price of AGAT is mainly affected by factors such as the number of users of Agates Network, the transaction volume of Agates Ecosystem, the total amount of AGAT that can be circulated, and the free circulation of AGAT within Agates Network. Agates Network regularly and dynamically calculates the official price of AGAT through a set of economic models that combine all the above factors.
        <br/>
        At the same time, Agates Network will adjust the total amount of AGAT flowing to the market through a flexible "supply curve", thereby affecting the price of AGAT to temporarily fluctuate or rise steadily to adapt to the needs of the project at different stages.
        <br />
        You can learn more details about Flexible Supply in the white paper.
      </Text>
    </div>

);
};

export default AGATPrice;

