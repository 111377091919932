import React from "react";
import { Text } from "../../components";

const ProjectMission = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        The Agates Network team believes that most of the value of the global Internet is created by its billions of users, so it should also be beneficial to these users. Therefore, the ultimate mission of Agates Network is to distribute the commercial benefits of Internet technology development more equitably among global users.
        <br/>
        In order to achieve this ultimate mission, we need to accomplish the following goals:
        <br/>

        <ol>
          <li>
          An Agates Mainnet that can complete data storage and transmission safely, efficiently and at low cost.
          </li>
          <li>
          An Agates Ecosystem covering multiple mature Internet businesses.
          </li>
          <li>
          A billion users from all over the world who are uniquely tagged with Agates ID.
          </li>
          <li>
          An AGAT token and its circulation platform that can complete precise value transmission in the ecosystem.
          </li>
          <li>
          A benefit distribution mechanism that is accurate, transparent and cannot be tampered with.
          </li>
        </ol>

        <br />
        You can learn more details about this topic in the white paper.

      </Text>
    </div>
);
};

export default ProjectMission;
