import React from "react";
import { Text } from "../../components";

const InviteParticipateRules = () => {
  return (

    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        Inviting friends to join Agates Network is even easier than joining Agates Network yourself.
        <br/>
        You only need to ask your friends to install the Agates Network App (install it with this link https://agates.me/applink or directly in Google Play or App Store), and then fill in your Agates ID in the Inviter’s Agates ID when signing up. Then it's done.
        <br/>
        Each of your invitees will bring you 5,000 Crediting AGAT coins and continuous Credited AGAT coins, which will increase the amount of USDT rewards you will receive from each event.
      </Text>
    </div>

);
};

export default InviteParticipateRules;
