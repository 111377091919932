import React from "react";
import { Text } from "../../components";

const ProjectFutureVison = () => {
  return (
    <div className={"flex-col items-start gap-3 px-6"}>
      <Text className="text-[14px] leading-[170.00%] text-gray-900 w-full"
            size="txtInterRegular14">

        The future vision of Agates Network encompasses a global ecosystem that empowers users worldwide through decentralized services.
        <br/>
        We envision a vibrant community where individuals can freely engage in e-commerce, digital content creation, social interactions, and gaming within a secure and transparent environment.
        <br/>
        Our goal is to become a leading platform that democratizes access to the benefits of internet technology, fostering economic empowerment, social inclusion, and innovation on a global scale.
        <br />
        Through continuous development, strategic partnerships, and community-driven initiatives, we aim to realize this vision and create a sustainable and impactful ecosystem for generations to come.
      </Text>
    </div>

);
};

export default ProjectFutureVison;

